export const Topbar = () => {
  return (
    <div className='header-top'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 col-md-5 d-none d-lg-block d-md-block'>
            <i className='fa fa-phone'></i> <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};
