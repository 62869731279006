import Menu from "./Menu";
import { Topbar } from "./Topbar";

export const Header = () => {
  return (
    <header>
      <Topbar />
      <Menu />
    </header>
  );
};
