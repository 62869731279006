import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from '../components/Home';
import { EstebanDetails } from '../pages/speakerDetails/EstebanDetails';
import JoseDetails from '../pages/speakerDetails/JoseDetails';
import TeresaDetails from '../pages/speakerDetails/TeresaDetails';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='DetallesE' element={<EstebanDetails />} />
      <Route path='DetallesJ' element={<JoseDetails />} />
      <Route path='DetallesT' element={<TeresaDetails />} />
    </Routes>
  );
};
