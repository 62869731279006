import React from 'react';
import CountdownTimer from './CountdownTimer';

const Presentacion = () => {
  const THREE_DAYS_IN_MS = 0 * 0 * 0 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  return (
    <main>
      <section className='hero' id='section_1'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-12 m-auto'>
              <div className='hero-text'>
                <h1 className='text-white mb-4'>
                  {/* <u className='text-info'>XII foro</u>*/}
                </h1>
                {/*
                <div className='d-flex justify-content-center align-items-center'>
                  <span className='date-text'>Mayo 18 al 20, 2023</span>

                  <span className='location-text'>
                    La Paz, Baja California Sur
                  </span>
                </div> */}

                <a
                  href='#section_2'
                  className='custom-link bi-arrow-down arrow-icon'
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div className='video-wrap'>
          <video
            src='videos/foro.mp4'
            autoPlay
            loop
            muted
            className='custom-video'
            poster=''
          ></video>
        </div>
      </section>

      <section className='highlight'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/Los-Cabos.mp4'
                  controls
                  // autoPlay
                  // loop
                  // muted
                  className='highlight-image '
                  poster=''
                ></video>
                {/* <img
                  src='images/highlight/alexandre-pellaes-6vAjp0pscX0-unsplash.jpg'
                  className='highlight-image img-fluid'
                  alt=''
                />*/}

                <div className='highlight-info'>
                  <h3 className='highlight-title '>Los Cabos</h3>

                  {/* <a
                    // href='https://www.mediafire.com/file/uzs1qlkxk9fujje/Baja_California_Sur.mov/file'
                    className='bi-youtube highlight-icon'
                  ></a> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/BCS.mov'
                  controls
                  // autoPlay
                  // loop
                  // muted
                  className='highlight-image img-fluid'
                  poster=''
                ></video>

                <div className='highlight-info'>
                  <h3 className='highlight-title'>Baja California Sur</h3>

                  {/* <a
                    href='https://www.youtube.com/templatemo'
                    className='bi-youtube highlight-icon'
                  ></a> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/Comondú.mov'
                  // autoPlay
                  // loop
                  // muted
                  controls
                  className='highlight-image img-fluid'
                  poster=''
                ></video>
                <div className='highlight-info'>
                  <h3 className='highlight-title'>Comundú</h3>

                  {/*<a
                    href='https://www.youtube.com/templatemo'
                    className='bi-youtube highlight-icon'
                  ></a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/La-Paz.mp4'
                  // autoPlay
                  // loop
                  //muted
                  controls
                  className='highlight-image img-fluid'
                  poster=''
                ></video>
                {/* <img
                  src='images/highlight/alexandre-pellaes-6vAjp0pscX0-unsplash.jpg'
                  className='highlight-image img-fluid'
                  alt=''
                />*/}

                <div className='highlight-info'>
                  <h3
                    className='highlight-title '
                    //style={{ marginBottom: '75%' }}
                  >
                    La Paz
                  </h3>

                  {/* <a
                    href='https://www.mediafire.com/file/uzs1qlkxk9fujje/Baja_California_Sur.mov/file'
                    className='bi-youtube highlight-icon'
                  ></a>*/}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/Mulegé.mov'
                  controls
                  // autoPlay
                  // loop
                  // muted
                  className='highlight-image img-fluid'
                  poster=''
                ></video>

                <div className='highlight-info'>
                  <h3 className='highlight-title'>Mulegé</h3>

                  {/* <a
                    href='https://www.youtube.com/templatemo'
                    className='bi-youtube highlight-icon'
                  ></a> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <div className='highlight-thumb'>
                <video
                  src='videos/Loreto.mov'
                  // autoPlay
                  // loop
                  //muted
                  controls
                  className='highlight-image img-fluid'
                  poster=''
                ></video>
                <div className='highlight-info'>
                  <h3 className='highlight-title'>Loreto</h3>

                  {/* <a
                    href='https://www.youtube.com/templatemo'
                    className='bi-youtube highlight-icon'
                  ></a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='call-to-action section-padding'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-7 col-12'>
              <h2 className='text-white mb-4'>
                <CountdownTimer targetDate={dateTimeAfterThreeDays} />
              </h2>

              <p className='text-white' style={{ textAlign: 'center' }}>
                Este <strong>18 al 20 de mayo del 2023 </strong> <br />
                en la Univerisidad Pedagógica Nacional, Unidad La Paz, Baja
                California Sur
              </p>

              <h4
                style={{
                  textAlign: 'center',
                  color: 'white',
                  marginTop: '15%',
                }}
              >
                Bienvenidos
              </h4>
            </div>

            <div className='col-lg-3 col-12 ms-lg-auto mt-4 mt-lg-0'>
              <a
                href='http://registrolie.creson.edu.mx/'
                className='custom-btn btn'
              >
                Registrate ahora
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Presentacion;
