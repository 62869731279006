import React from 'react';

export const Documental = () => {
  return (
    <section className='highlight'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-12'>
            <iframe
              width='350'
              height='315'
              src='https://www.youtube.com/embed/YQy_roRfZ1E'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              autoPlay
              allowFullScreen
            ></iframe>
          </div>

          <div className='col-lg-4 col-md-6 col-12'>
            <iframe
              width='350'
              height='315'
              src='https://www.youtube.com/embed/q6IfboxXqLQ'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              autoPlay
              allowFullScreen
            ></iframe>
          </div>

          <div className='col-lg-4 col-md-6 col-12'>
            <iframe
              width='350'
              height='315'
              src='https://www.youtube.com/embed/-ufSVf4pWIk'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              autoPlay
              allowFullScreen
            ></iframe>
          </div>

          <div className='col-lg-4 col-md-6 col-12'>
            <iframe
              width='350'
              height='315'
              src='https://www.youtube.com/embed/GpVFZfCeONc'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              autoPlay
              allowFullScreen
            ></iframe>
          </div>
          <div className='col-lg-4 col-md-6 col-12'>
            <iframe
              width='350'
              height='315'
              src='https://www.youtube.com/embed/_9j742hzqDA'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              autoPlay
              allowFullScreen
            ></iframe>
          </div>
          <h4
            style={{
              textAlign: 'center',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          >
            Podcast
          </h4>
          <iframe
            src='https://open.spotify.com/embed?uri=spotify:show:1uCIWiRcmSPppGKaQjNZK3'
            width='300'
            height='380'
            frameBorder='0'
            allowtransparency='true'
            allow='encrypted-media'
          ></iframe>
        </div>
      </div>
    </section>
  );
};
