import React from 'react';
import { Documental } from '../Documental';
import Carousel from 'react-bootstrap/Carousel';

const Shedules = () => {
  return (
    <section className='schedule section-padding' id='section_4'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <h2 className='mb-5 text-center'>
              Ponencias <u className='text-info'></u>
            </h2>
            <h4 className='mb-5 text-center' marginTop='2'>
              Carteles-Póster
            </h4>
            <div className='tab-content mt-5' id='nav-tabContent'>
              <div
                className='tab-pane fade show active'
                id='nav-DayOne'
                role='tabpanel'
                aria-labelledby='nav-DayOne-tab'
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* ********************************************* */}
      <Carousel>
        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Nueva Modalidad Viable.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Nueva Modalidad viable
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Cervantes Soqui - María Fernanda
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\INTERVENTOR EDUCATIVO Y SU IMPACTO EN PROYECTOS SOCIOEDUCATIVOS.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Interventor educativo y su impacto en proyectos
                  socioeducativos
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Yeritza Váldez - Iveth Díaz
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Porqué es importante el reciclaje.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Porqué es importante el reciclaje
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Pineda Jennifer - Yánez Dominik -Morales Erika - Lugo Lizbeth
                  -Fabela Desire
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Desarrollo comunitario para la atención socioeducativa.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Desarrollo comunitario para la atención socioeducativa
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Milagros Mendívil - Marlene Ruíz
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Grupos en situación de riesgo.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Grupos en situación de riesgo
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Astorga Marcos - Ortega Francisco
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Los Énfasis de la Intervención Educativa en la Transformación Social.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Los Énfasis de la Intervención Educativa en la Transformación
                  Social
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Carlos Rascón
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Impulsemos a los NIños, NIñas y Adolescentes como agentes de cambio social.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Impulsemos a los NIños, NIñas y Adolescentes como agentes de
                  cambio social
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Espinosa Peñas - Rocha Cristel
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Por una educación igual para todos.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Por una educación igual para todos
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Azul Díaz
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* 3 */}
        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Proyecto comunitario.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Proyecto comunitario
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Sarahi Carreño - María Martínez - Rocío leyva -Luis Espinosa
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\La intervención educativa durante y después de la pandemia( formal, no formal e informal).jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  La intervenión educativa durante y después de la pandemia
                  (formal, no formal informal)
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Joann Rivas - Karla Campoy
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Los coloridos caminos de la enseñanza y del aprendizaje.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Los coloridos caminos de la enseñanza y del aprendizaje
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Ángulo Camacho Melissa
                  <br />
                  Gastélum Olivares Melanie Yamileth
                  <br />
                  Gavia Ontiveros Ana Gabriela
                  <br />
                  Gaxiola Córdova Noelia Esmeralda
                  <br />
                  Jocobi Encinas Ariam Guadalupe
                  <br />
                  López Quintana Carmen Paola.
                  <br />
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Por una educación igual para todos.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Por una educación igual para todos
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Azul Díaz
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/*4 ***** */}

        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Proyecto comunitario.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Proyecto comunitario
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Sarahi Carreño - María Martínez - Rocío leyva -Luis Espinosa
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\La intervención educativa durante y después de la pandemia( formal, no formal e informal).jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  La intervenión educativa durante y después de la pandemia
                  (formal, no formal informal)
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Joann Rivas - Karla Campoy
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Poster, Diversidad Cultural y Linguistica 3.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Póster diversidad
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Naomi Plata
                  <br />
                  Damaris Murillo
                  <br />
                  Miriam Lemus
                  <br />
                  Kenia Flores
                  <br />
                  Osmara Arce
                  <br />
                  Mariam Higuera.
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Por una educación igual para todos.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Inclusión educativa
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Patricia López
                  <br /> Danna Madrigal
                  <br />
                  Juanita Osuna
                  <br /> Itzel Hermosillo
                  <br />
                  Naomi Del Carmen Hernandez
                  <br /> Marisol Gómez
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* ******5** */}

        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Proyecto comunitario.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Proyecto de intervención "Inclusión educativa"
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  García Santos Ariadna Lizbeth
                  <br />
                  Hernández Zazueta Arleth Yuriana
                  <br />
                  Puebla Peraza Mariana
                  <br />
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\El rezago educativo en la absorción del nivel superior.jpeg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  El rezago educativo en la absorción del nivel superior
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Héctor Alejandro Wancho Baltazar
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\El valor del arte en el proceso educativo.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  El valor del arte en el proceso educativo
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Alexandra Renee Moreno Simentales
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Reincorporación, nuevas necesidades y cambios después de la pandemia.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Reincorporación, nuevas necesidades y cambios después de la
                  pandemia
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Roberto Yates García
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* ****6*** */}

        <Carousel.Item>
          <div className='row border-bottom pb-5 mb-5'>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Historia cultural de Mexicali.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Historia cultural de Mexicali
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  García Arias Mónica M.
                  <br />
                  García Arias Erick C.
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\El rezago educativo en la absorción del nivel superior.jpeg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  La importancia de la enseñanza en RED
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Melanin Karina Ordóñez Gómez
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\La importancia de la Enseñanza en RED.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  El valor del arte en el proceso educativo
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  Alexandra Renee Moreno Simentales
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-12'>
              <img
                src='images\Cartel-Póster(jpg)\Rezago educativo en México.jpg'
                className='schedule-image img-fluid'
                alt=''
                style={{ width: '100%', height: 'auto' }}
              />

              <div className='mt-3'>
                <h4 style={{ fontFamily: 'Arial', fontSize: '16px' }}>
                  Rezago educativo en México
                </h4>
                <p
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    marginBottom: '0',
                  }}
                >
                  María Isabel Rodríguez Flores
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* ******************* */}

      <div style={{ marginTop: '5%', textAlign: 'center' }}>
        <h3>Documentales</h3>
        <Documental />
      </div>
    </section>
  );
};

export default Shedules;
