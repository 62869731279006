import React from 'react';
import { Link } from 'react-router-dom';

const Speakers = ({ speakers = [] }) => {
  return (
    <section className='speakers section-padding ' id='section_3'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-12 d-flex flex-column justify-content-center align-items-center'>
            <div className='speakers-text-info  '>
              <h2>Conferencistas</h2>
            </div>
          </div>

          {speakers.map((s) => (
            <div key={s.name} className='col-lg-4 col-12'>
              <div
                style={{
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  marginRight: '2px',
                }}
              >
                <div className='speakers-thumb'>
                  <img
                    src={
                      s.picture
                        ? s.picture
                        : 'images/avatar/happy-asian-man-standing-with-arms-crossed-grey-wall.jpg'
                    }
                    className='img-fluid speakers-image'
                    alt=''
                  />

                  <small className='speakers-featured-text'>
                    Conferencistas
                  </small>

                  <div className='speakers-info'>
                    <h5
                      className='speakers-title mb-0'
                      style={{ fontSize: '22px', textAlign: 'center' }}
                    >
                      <Link to={`${s.rote}`}> {s.name}</Link>
                    </h5>

                    <p
                      className='speakers-text mb-0'
                      style={{ textAlign: 'center' }}
                    >
                      {s.puesto}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Speakers;
