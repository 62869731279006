import React from "react";

const Fotter = () => {
  return (
    <footer className='site-footer'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-12 col-12 border-bottom pb-5 mb-5'></div>

          <div className='col-lg-12 col-12  pb-5 mb-5'>
            <div>
              <a href='/' style={{ marginLeft: "43%" }}>
                <img
                  src='images/img/lie-u2152.png'
                  style={{ width: "170px" }}
                  alt='1er Foro'
                />
              </a>
            </div>
            <div>
              <p style={{ textAlign: "center", marginTop: "15px" }}>
                <b> xiifororegional.lie@creson.edu.mx </b>
              </p>
              <ul
                className='social-icon ms-auto'
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <li>
                  <a href='/' className='social-icon-link bi-facebook'></a>
                </li>

                <li>
                  <a href='/' className='social-icon-link bi-instagram'></a>
                </li>

                <li>
                  <a href='/' className='social-icon-link bi-whatsapp'></a>
                </li>

                <li>
                  <a href='/' className='social-icon-link bi-youtube'></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Fotter;
