import React from 'react';

function JoseDetails() {
  return (
    <section id='speakers-details' className='wow fadeIn'>
      <div className='container mb-5 mt-5'>
        {/* <div style={{ textAlign: " center" }}>
          <h2 className='mb-5 mt-5'>Semblanza</h2>
        </div> */}

        <div className='row'>
          <div className='col-md-5 mt-5'>
            <img
              src='/img/event/dr-jose.png'
              alt='Speaker 1'
              className='img-fluid'
            />
          </div>

          <div className='col-md-7'>
            <div className='details'>
              <h2 className='mb-5'>José Luis Molina Hernández</h2>
              <p>ESTUDIOS REALIZADOS</p>
              <ul>
                <li>
                  Licenciatura en Sociología en la Escuela de Ciencias Sociales
                  y Políticas de la U.A.B.C., de 1984 a 1988.
                </li>
                <li>
                  Maestría en Desarrollo Urbano en la Facultad de Arquitectura y
                  el Instituto de Investigaciones Sociales de la Universidad
                  Autónoma de Baja California, de 1989 a 1991.
                </li>
                <li>
                  Doctorado en Ciencias Sociales por El Colegio de la Frontera
                  Norte, 1994-1998.
                </li>
              </ul>
              <p>EXPERIENCIA PROFESIONAL</p>
              <ul>
                <li>
                  Coordinador de Estudios Socioeconómicos de la Comisión para el
                  Ahorro de Energía del Municipio de Mexicali, desde enero de
                  1990 hasta septiembre de 1991.
                </li>
                <li>
                  Presidente del Colegio Estatal de Sociólogos de Baja
                  California, desde marzo de 1999 a marzo de 2001.
                </li>
                <li>
                  Director de la Universidad Pedagógica Nacional, Unidad
                  Mexicali, de enero de 2009 a enero de 2011.
                </li>
                <li>
                  Presidente de Observadores Ciudadanos de la Educación de Baja
                  California, desde marzo de 2009 a la fecha.
                </li>
                <li>
                  Consejero Ciudadano Suplente por el Distrito I de Baja
                  California, para los procesos electorales 2012 y 2015.
                </li>
              </ul>
              <p>EXPERIENCIA DOCENTE Y ACADÉMICA</p>
              <ul>
                <li>
                  Profesor de la Universidad Pedagógica Nacional desde abril
                  de1989 a la fecha.
                </li>
                <li>
                  Profesor de la Escuela de Ciencias Sociales y Políticas de la
                  U.A.B.C. de 1990 a 1994.
                </li>
                <li>
                  Profesor en diversas instituciones de educación superior, como
                  la Universidad Iberoamericana, Campus Tijuana, el Centro
                  Universitario de Tijuana, el CETyS Campus Mexicali, y San
                  Diego State University, Campus Long Beach.
                </li>
              </ul>
              <p>EXPERIENCIA EN INVESTIGACIÓN</p>
              <ul>
                <li>
                  Miembro del Sistema Nacional de Investigadores, nivel I, desde
                  julio de 2001 a junio de 2004, y de enero de 2006 a diciembre
                  de 2008.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoseDetails;
