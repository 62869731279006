import React from 'react';
import './styles.css';
import {
  faFileWord,
  faFilm,
  faPhotoFilm,
  faScroll,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Modality = () => {
  return (
    <section className='schedule section-padding' id='section_4'>
      <div className='popular-categories'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-heading '>
                <h2>Modalidades de Participación</h2>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='naccs'>
                <div className='grid'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='menu'>
                        <div className='first-thumb active'>
                          <div className='thumb'>
                            <span className='icon '>
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className='iconStyle'
                              />
                            </span>
                            Ponencias
                          </div>
                        </div>
                        <div>
                          <div className='thumb'>
                            <span className='icon'>
                              <FontAwesomeIcon
                                icon={faScroll}
                                className='iconStyle'
                              />
                            </span>
                            Pósters
                          </div>
                        </div>
                        <div>
                          <div className='thumb'>
                            <span className='icon'>
                              <FontAwesomeIcon
                                icon={faPhotoFilm}
                                className='iconStyle'
                              />
                            </span>
                            Podcast
                          </div>
                        </div>
                        <div className='first-thumb active'>
                          <div className='thumb'>
                            <span className='icon '>
                              <FontAwesomeIcon
                                icon={faFilm}
                                className='iconStyle'
                              />
                            </span>
                            Documental
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-9 align-self-center'>
                      <ul className='nacc'>
                        <li className='active'>
                          <div>
                            <div className='thumb'>
                              <div className='row'>
                                <h4 className='mt-2 mb-3'>
                                  Las ponencias deberán cumplir con los
                                  siguientes requisitos:
                                </h4>

                                <div className='col-lg-12 align-self-center'>
                                  <div className='left-text'>
                                    <p style={{ fontSize: '15px' }}>
                                      Estar elaboradas en formato WORD,
                                      tipografia arial 12, interlineado 1.5, con
                                      un minimo de 3 cuatillas y un máximo de 5
                                      cuartillas (no incluya la hoja de portada
                                      ni de bibliografia, formato APA.)
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Los trabajos como participante podrán ser
                                      realizados de manera individual o por
                                      equipo <br /> de tres personas como
                                      maximos.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Presentación en plantilla PowerPoint
                                    </p>

                                    <p style={{ fontSize: '15px' }}>
                                      Anexar datos completos del (os) autor
                                      (es): nombre, domicilio, teléfono
                                      particular y celular, e-mail, Unida UPN-
                                      UPNECH- UPN, cargo, (de cada autor) y
                                      nombre del eje temático en el que se ubica
                                      la ponencia,{' '}
                                      <b>de no señalar no serán aceptadas.</b>
                                    </p>

                                    <p style={{ fontSize: '15px' }}>
                                      Calendarización para el registro y entrega
                                      de ponencias de manera escrita y en power
                                      point (La duración de la presentación será
                                      de 10 min. por participante o colectivo )
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Envío de ponencias al correo electrónico{' '}
                                      <b>xiifororegional.lie@creson.edu.mx</b> o
                                      a través de liga de descarga (Drive o
                                      Wetransfer)
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      El formato de presentación en power point
                                      para sus ponencias lo pueden obtener en
                                      este link:
                                      <a
                                        href=' https://docs.google.com/presentation/d/1F9e0wAPYLEkP97yLaY5PBFqPEd9gB6-k/edit?usp=sharing&ouid=114331293398395639718&rtpof=true&sd=true'
                                        style={{ color: 'blue' }}
                                      >
                                        {' '}
                                        Formato presentación
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className='thumb'>
                              <div className='row'>
                                <h2 className='mt-2 mb-1'>Póster</h2>
                                <div className='col-lg-7 align-self-center'>
                                  <div className='left-text'>
                                    <p style={{ fontSize: '13px' }}>
                                      Título del trabajo, autor (es), Unidad o
                                      campus en donde estudia, línea específica
                                      que cursa (2do, 4to y 6to semestres),
                                      correo electrónico, semestre.
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      Descripción general de la problemática,
                                      tema o proceso y ámbito(s) de
                                      intervención, metodología, productos
                                      esperados, referencias.
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      {' '}
                                      Como sugerencia, se puede incluir el
                                      nombre de (l) asesor(es), o asesora(as).
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      Dimensiones de poster: tamaño: 60 x 90 cm
                                      (papel), presentación: máximo 10 minutos.
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      Envío de póster al correo electrónico
                                      <b>
                                        {' '}
                                        xiifororegional.lie@creson.edu.mx
                                      </b>{' '}
                                      o a través de liga de descarga (Drive o
                                      Wetransfer)
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      Contenido relacionado con los ejes
                                      temáticos del foro.
                                    </p>
                                    <p style={{ fontSize: '13px' }}>
                                      Los derechos para la utilización,
                                      reproducción y difusión, serán cedidos al
                                      comité organizador del foro
                                    </p>
                                  </div>
                                </div>
                                <div className='col-lg-5 align-self-center mb-4'>
                                  <div className='right-image'>
                                    <img
                                      src='images/img/poster.png'
                                      alt='Foods on the table'
                                      style={{
                                        maxWidth: '270px',
                                        marginRight: '34px',
                                        paddingRight: '24px',
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className='thumb'>
                              <div className='row'>
                                <h4 className='mt-2 mb-3'>Podcast</h4>
                                <div className='col-lg-12 align-self-center'>
                                  <div className='left-text'>
                                    <p style={{ fontSize: '15px' }}>
                                      Archivo inédito
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Formato Mp3
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Duración entre 5 y 8 minutos.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Envío de podcast al correo electrónico
                                      <b>
                                        {' '}
                                        xiifororegional.lie@creson.edu.mx
                                      </b>{' '}
                                      o a través de liga de descarga (Drive o
                                      Wetransfer)
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Los derechos para la utilización,
                                      reproducción y difusión, serán cedidos al
                                      comité organizador del foro.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className='thumb'>
                              <div className='row'>
                                <h4 className='mt-2 mb-3'>Documental</h4>
                                <div className='col-lg-12 align-self-center'>
                                  <p
                                    style={{
                                      fontSize: '15px',
                                    }}
                                  >
                                    Participación individual o por equipos
                                    <br />
                                    Duración máxima 8 minutos incluyendo
                                    créditos. <br />
                                    Puede ser grabado en cualquier tipo de
                                    animación y/o acción viva. <br />
                                    Formato de video: Mp4 <br />
                                    No se permite uso de clichés televisivos,
                                    música comercial ni lenguaje obsceno.
                                    <br /> Los derechos para la utilización,
                                    reproducción y difusión, serán cedidos al
                                    comité organizador del foro. <br />
                                    Contenido relacionado con los ejes temáticos
                                    del foro.
                                    <br /> Envío de documental al correo
                                    electrónico
                                    <b> xiifororegional.lie@creson.edu.mx</b> o
                                    a través de liga de descarga (Drive o
                                    Wetransfer)
                                    <br />
                                    Incluir en créditos a creadores,
                                    herramientas, investigación, etc.
                                    <br /> Nombrar a todos los creadores
                                    intelectuales de la obra así como dar
                                    crédito de las herramientas que se
                                    utilizaron y la investigación que se empleó
                                    para realizar el trabajo. Dichos créditos
                                    deberán aparecer al final.
                                  </p>
                                  <p
                                    style={{
                                      marginLeft: '34px',
                                      fontSize: '15px',
                                    }}
                                  >
                                    El título del documento tiene que coincidir
                                    con el título del trabajo.
                                  </p>
                                  <p
                                    style={{
                                      marginLeft: '34px',
                                      fontSize: '15px',
                                    }}
                                  >
                                    {' '}
                                    Además del documento de audio o audiovisual,
                                    el (los) autor (es) deberán proporcionar un
                                    documento de texto en formato Word que
                                    deberá contar con:
                                  </p>
                                  <p
                                    style={{
                                      marginLeft: '64px',
                                      fontSize: '15px',
                                    }}
                                  >
                                    {' '}
                                    Un resumen en español para fines de
                                    indización y recuperación con una extensión
                                    aproximada de 100 palabras. <br />
                                    Una introducción que explique brevemente los
                                    objetivos del documento de audio o
                                    audiovisual. <br />
                                    Una conclusión por escrito del trabajo
                                    realizado.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
