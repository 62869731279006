import React from 'react';
import { base } from '../data/base';
import Conferences from './Conferences';
import { Modality } from './Modality';
import Object from './Object';
import Presentacion from './Presentacion';
import Speakers from './Speakers';
import { Sponsors } from './Sponsors';
import { Tematicas } from './Tematicas';
import Shedules from './Shedules';

export const Home = () => {
  return (
    <>
      <Presentacion />
      <Object />
      <Speakers speakers={base.mainSpeakers} />
      <Conferences speakers={base.speakers} />

      <Tematicas />
      <Modality />
      <Shedules />
      <Sponsors />
    </>
  );
};
