import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './styles.css';
export const Tematicas = () => {
  return (
    <section id='faq' className='wow fadeInUp speakers'>
      <div className='container'>
        <div className='section-header mb-5'>
          <h2 style={{ textAlign: 'center' }}>Líneas temáticas</h2>
        </div>

        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <ul id='faq-list'>
              <li>
                <a data-toggle='collapse' className='collapsed' href='#faq1'>
                  Atención a la diversidad cultural y lingüística
                  <i>
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </i>
                </a>
                <div id='faq1' className='collapse' data-parent='#faq-list'>
                  <p>
                    a. Grupos en situación de riesgo.
                    <br />
                    b. Inclusión educativa.
                    <br />
                    c. Migración y Educación.
                    <br />
                    d. Albergues y educación.
                    <br />
                    e. Grupos minoritarios.
                    <br />
                    f. Niñez, adolescencia y adultos.
                    <br />
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle='collapse' href='#faq2' className='collapsed'>
                  Experiencias académicas para atender el rezago educativo
                  <i>
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </i>
                </a>
                <div id='faq2' className='collapse' data-parent='#faq-list'>
                  <p>
                    a. Propuestas de atención.
                    <br />
                    b. Brigadas de atención.
                    <br />
                    c. Proyectos de atención.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle='collapse' href='#faq3' className='collapsed'>
                  Desarrollo comunitario para la atención socioeducativa
                  <i>
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </i>
                </a>
                <div id='faq3' className='collapse' data-parent='#faq-list'>
                  <p>
                    a. Medio Ambiente.
                    <br />
                    b. Proyectos Comunitarios.
                    <br />
                    c. Diversidad Cultural.
                    <br />
                    d. Inclusión educativa
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle='collapse' href='#faq4' className='collapsed'>
                  El interventor educativo e impacto en los proyectos
                  socioeducativos
                  <i>
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </i>
                </a>
                <div id='faq4' className='collapse' data-parent='#faq-list'>
                  <p>
                    a. El interventor educativo, su desarrollo profesional e
                    inserción laboral.
                    <br />
                    b. Educación a distancia: nueva modalidad viable para dar
                    cobertura a la educación superior.
                    <br />
                    c. Distintos contextos y necesidades de atención
                    socioeducativa.
                    <br />
                    d. Atención o acompañamiento a diferentes agentes
                    educativos.
                    <br />
                    e. Prácticas pedagógicas diseñadas para el mejoramiento de
                    aprendizajes.
                    <br />
                    f. Diseño y evaluación de proyectos que promueven el
                    desarrollo de la niñez, adolescencia y adultez.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle='collapse' href='#faq5' className='collapsed'>
                  La intervención educativa durante y después de la pandemia
                  <i>
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </i>
                </a>
                <div id='faq5' className='collapse' data-parent='#faq-list'>
                  <p>
                    a. El perfil de egreso de las licenciaturas después de la
                    pandemia.
                    <br />
                    b. Promoción de la inclusión y la accesibilidad a las nuevas
                    tecnologías para la enseñanza
                    <br />
                    c. El trabajo del interventor en escenarios de aprendizaje
                    híbridos.
                    <br />
                    d. Ambientes de aprendizaje después de la pandemia.
                    <br />
                    Impacto de la educación socioemocional entre crisis por
                    covid-19.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
