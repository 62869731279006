import React from 'react';

function TeresaDetails() {
  return (
    <section id='speakers-details' className='wow fadeIn'>
      <div className='container mb-5 mt-5'>
        {/* <div style={{ textAlign: " center" }}>
          <h2 className='mb-5 mt-5'>Semblanza</h2> 
        </div>*/}

        <div className='row'>
          <div className='col-md-5 mt-5'>
            <img
              src='/img/event/Teresa_Negret.png'
              alt='Speaker 1'
              className='img-fluid'
            />
          </div>

          <div className='col-md-7'>
            <div className='details'>
              <h2 className='mb-5'>Teresa de Jesús Negrete Arteaga</h2>

              <p>
                Es pedagoga por la UNAM, maestra y doctora en Ciencias con
                Especialidad en Investigaciones Educativas del DIE-CINVESTAV.
                Fue miembro del Sistema Nacional de Investigadores del 2013 al
                2018. Actualmente es perfil PRODEP. Desde 1987 impulsó el
                desarrollo académico e institucional de las 76 Unidades UPN del
                país, participó en el diseño y desarrollo curricular de planes y
                programas para maestros de educación básica (LEB79; LEP Y LEP
                85; LE 94) y fue Coordinadora Nacional de la Licenciatura en
                Intervención Educativa. A partir del 1996 desarrolló
                investigaciones y estudios sobre la UPN y en las Escuelas
                Normales del D.F.
              </p>

              <p>
                Así mismo, investiga desde el 2006 la emergencia del campo de la
                intervención educativa en México y desde el año 2008 es
                responsable en México del proyecto internacional: “Investigación
                e intervención educativa comparada entre
                México-España-Argentina”, en el marco del convenio de
                colaboración interinstitucional de la Universidad Pedagógica
                Nacional por México; la Universidad Jaume I de Castellón por
                España y el intercambio con colegas de la Universidad Nacional
                de Córdoba, La Universidad de Buenos Aires, La Universidad de
                Entre Ríos y la Universidad de Rosario por Argentina.
              </p>

              <p>Líneas de investigación. </p>
              <ul>
                <li>
                  Investigación-Intervención: Cultura digital y procesos de
                  subjetivación en la vida contemporánea.
                </li>
                <li>
                  Trayectorias de formación de interventores educativos
                  (docentes y actores sociales), curriculum y dominios de la
                  profesión.
                </li>
                <li>
                  El papel de la tutoría y el desarrollo de proyectos de
                  intervención educativa en espacios escolares y sociales.
                </li>
                <li>
                  Aspectos conceptuales y metodológicos entre investigación e
                  intervención educativa en contextos de emergencia.
                </li>
              </ul>

              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeresaDetails;
