import React from 'react';

const Object = () => {
  return (
    <section className='about section-padding' id='section_2'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-112 col-12'>
            <h2 className='mb-5'>Propósito</h2>
          </div>

          <div className='col-lg-6 col-12' style={{ textAlign: ' left' }}>
            <p>
              Dialogar sobre la formación profesional del interventor educativo
              para enfrentar los retos que las necesidades sociales y educativas
              emergentes demandan de un profesional de la educación polivalente,
              donde plantean nuevas perspectivas de desarrollo profesional a
              través del diálogo y la convivencia académica.
            </p>

            <a
              className='custom-btn custom-border-btn btn custom-link mt-3 me-3'
              href='http://registrolie.creson.edu.mx/'
            >
              Registro
            </a>

            <a
              className='custom-btn custom-border-btn btn custom-link mt-3 me-3'
              href='#section_4'
            >
              Programa
            </a>
          </div>

          <div
            className='col-lg-6 col-12 mt-5 mt-lg-0'
            style={{ textAlign: ' left' }}
          >
            <h6>
              A realizarse los días{' '}
              <b style={{ color: '#0d6efd' }}> 18, 19 y 20 de mayo de 2023</b>{' '}
              en las instalaciones de la Univerisidad Pedagógica Nacional,
              Unidad La Paz, Baja California Sur
            </h6>

            <div className='avatar-group border-top py-5 mt-5'>
              <img
                src='/img/event/Teresa_Negret.png'
                className='img-fluid avatar-image'
                alt='Dra.Teresa'
              />
              <img
                src='/img/event/estebandr.jpg'
                className='img-fluid avatar-image avatar-image-left'
                alt='Dr.Estebas'
              />

              <img
                src='/img/event/dr-jose.png'
                className='img-fluid avatar-image avatar-image-left'
                alt='Dr.Jose'
              />

              <p className='d-inline'>Conferencistas</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Object;
