export const base = {
  mainSpeakers: [
    {
      name: 'Teresa de Jesús Negrete Arteaga',
      profile: `Es pedagoga por la UNAM, maestra y doctora en Ciencias con Especialidad en Investigaciones Educativas del DIE-CINVESTAV. Fue miembro del Sistema Nacional de Investigadores del 2013 al 2018. Actualmente es perfil PRODEP. Desde 1987 impulsó el desarrollo académico e institucional de las 76 Unidades UPN del país, participó en el diseño y desarrollo curricular de planes y programas para maestros de educación básica (LEB79; LEP Y LEP 85; LE 94) y fue Coordinadora Nacional de la Licenciatura en Intervención Educativa. A partir del 1996 desarrolló investigaciones y estudios sobre la UPN y en las Escuelas Normales del D.F.
      Así mismo, investiga desde el 2006 la emergencia del campo de la intervención educativa en México y desde el año 2008 es responsable en México del proyecto internacional: “Investigación e intervención educativa comparada entre México-España-Argentina”,  en el marco del convenio de colaboración interinstitucional de la Universidad Pedagógica Nacional por México; la Universidad Jaume I de Castellón por España y el intercambio con colegas de la Universidad Nacional de Córdoba, La Universidad de Buenos Aires, La Universidad de Entre Ríos y la Universidad de Rosario por Argentina. 
      
      `,
      picture: '/img/event/Teresa_Negret.png',
      puesto: 'UPN Ajusco',
      rote: '/DetallesT',
    },

    {
      name: 'Esteban García Hernández',
      profile: `Actualmente es docente de la Unidad Virtual de la Universidad Pedagógica Nacional del Estado de Chihuahua.

      Profesor de educación primaria, egresado de instituciones del estado de Chihuahua: la escuela normal Luis Urías Belderrain y la Universidad Pedagógica, donde realizó estudios de licenciatura, maestría y obtuvo el grado de Doctor en Desarrollo Educativo con Énfasis en Formación de Profesores. 
      
      Es miembro activo de la Red de Investigación de Educación Rural (RIER) desde donde ha colaborado con académicos de la UPN para el diseño curricular de la Licenciatura en Educación Multigrado (LEM), participó además en el diseño de la Maestría en Educación Rural que ha implementado la UPNECH.
      
      Como integrante de la consultoría Heurística Educativa, colaboró en investigaciones nacionales: Evaluación Cualitativa del Programa Escuelas de Calidad y Monitoreo del Programa Dialogar y Descubrir del CONAFE; y estudios internacionales centrados en el análisis de la práctica docente en Ecuador, Nicaragua, República Dominicana y Paraguay.
      
      De manera reciente ha publicado diversos artículos y ponencias referidas a la educación para las hijas e hijos de familias jornaleras agrícolas migrantes.
       `,

      picture: '/img/event/estebandr.jpg',
      puesto: 'Chihuahua',
      rote: '/DetallesE',
    },

    {
      name: 'José Luis Molina Hernández',
      profile: `Actualmente es docente de la Unidad Virtual de la Universidad Pedagógica Nacional del Estado de Chihuahua.

      Profesor de educación primaria, egresado de instituciones del estado de Chihuahua: la escuela normal Luis Urías Belderrain y la Universidad Pedagógica, donde realizó estudios de licenciatura, maestría y obtuvo el grado de Doctor en Desarrollo Educativo con Énfasis en Formación de Profesores. 
      
      Es miembro activo de la Red de Investigación de Educación Rural (RIER) desde donde ha colaborado con académicos de la UPN para el diseño curricular de la Licenciatura en Educación Multigrado (LEM), participó además en el diseño de la Maestría en Educación Rural que ha implementado la UPNECH.
      
      Como integrante de la consultoría Heurística Educativa, colaboró en investigaciones nacionales: Evaluación Cualitativa del Programa Escuelas de Calidad y Monitoreo del Programa Dialogar y Descubrir del CONAFE; y estudios internacionales centrados en el análisis de la práctica docente en Ecuador, Nicaragua, República Dominicana y Paraguay.
      
      De manera reciente ha publicado diversos artículos y ponencias referidas a la educación para las hijas e hijos de familias jornaleras agrícolas migrantes.
       `,

      picture: '/img/event/dr-jose.png',
      puesto: 'Baja California',
      rote: '/Detallesj',
    },
  ],

  speakers: [
    {
      name: 'Consuelo Lozano Perez',
      institucion: 'UPN Tijuana',
      picture: 'img/talleristas/4.png',
    },
    {
      name: 'Jerónimo Gamiño Ramírez',
      institucion: 'UPN Mexicali',
      picture: 'img/talleristas/1.png',
    },

    {
      name: 'Josefina Pérez Espino',
      institucion: 'UPN Tijuana',
      picture: 'img/talleristas/3.png',
    },
    {
      name: 'Issac Eloy Rodriguez ',
      institucion: 'UPN Mexicali',
      picture: 'img/talleristas/2.png',
    },

    {
      name: 'Amanda Araceli Chang López ',
      institucion: 'UPN Tijuana',
      picture: 'img/talleristas/5.png',
    },
    {
      name: ' Rosa Linda Ortega García ',
      institucion: 'La Paz',
      picture: 'img/talleristas/6.png',
    },
    {
      name: 'Sofía de la Mora Campos',
      institucion:
        'Departamento de Educación y Comunicación, Universidad Autónoma Metropolitana, Xochimilco',
      picture: 'img/talleristas/7.png',
    },
    {
      name: 'Francisco Jesus Carvajal Moncada  ',
      institucion: 'UPN Obregón',
      picture: 'img/talleristas/8.png',
    },
    {
      name: 'Alfredo Romero Velis  ',
      institucion: 'Upn La Paz',
      picture: 'img/talleristas/9.png',
    },
    {
      name: 'Esteban García Hernández ',
      institucion: 'UPN CHIHUAHUA',
      picture: 'img/talleristas/10.png',
    },
    {
      name: 'Leonel Isaac García ',
      institucion: 'Sonora',
      picture: 'img/talleristas/leonel5.png',
    },
    {
      name: 'Martha Susana Rivera Ávalos',
      institucion: 'UPN TIJUANA',
      picture: 'img/talleristas/martha_usana.jpg',
    },
    {
      name: 'Rosaura Guerrero Molina',
      institucion: 'UPN TIJUANA',
      picture: 'img/talleristas/Rosaura.jpg',
    },
  ],

  participacion: [
    {
      format: 'Ponencia',
    },
  ],
};
