import React from "react";

export const Sponsors = () => {
  return (
    <section id='supporters' className='section-with-bg wow fadeInUp'>
      <div className='container'>
        <div className='section-header'>{/* <h2>Sponsors</h2> */}</div>

        <div className='row no-gutters supporters-wrap clearfix'>
          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img src='img/sponsors/pn.png' className='img-fluid' alt='' />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img
                src='img/sponsors/cresonH.png'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img
                src='img/sponsors/upnchic.png'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img
                src='img/sponsors/mexicali.png'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img
                src='img/sponsors/upnTijuana.png'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img src='img/sponsors/upn03A.png' className='img-fluid' alt='' />
            </div>
          </div>

          <div className='col-lg-3 col-md-4 col-xs-6'>
            <div className='supporter-logo'>
              <img src='img/sponsors/Lie.png' className='img-fluid' alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
