import React from 'react';

export const EstebanDetails = () => {
  return (
    <section id='speakers-details' className='wow fadeIn'>
      <div className='container mb-5 mt-5'>
        {/* <div style={{ textAlign: " center" }}>
          <h2 className='mb-5 mt-5'>Semblanza</h2>
        </div> */}

        <div className='row'>
          <div className='col-md-5 mt-5'>
            <img
              src='/img/event/estebandr.jpg'
              alt='Speaker 1'
              className='img-fluid'
            />
          </div>

          <div className='col-md-7'>
            <div className='details'>
              <h2 className='mb-5'>Esteban García Hernández</h2>

              <p>
                Actualmente es docente de la Unidad Virtual de la Universidad
                Pedagógica Nacional del Estado de Chihuahua.
              </p>

              <p>
                Profesor de educación primaria, egresado de instituciones del
                estado de Chihuahua: la escuela normal Luis Urías Belderrain y
                la Universidad Pedagógica, donde realizó estudios de
                licenciatura, maestría y obtuvo el grado de Doctor en Desarrollo
                Educativo con Énfasis en Formación de Profesores.
              </p>

              <p>
                Es miembro activo de la Red de Investigación de Educación Rural
                (RIER) desde donde ha colaborado con académicos de la UPN para
                el diseño curricular de la Licenciatura en Educación Multigrado
                (LEM), participó además en el diseño de la Maestría en Educación
                Rural que ha implementado la UPNECH.
              </p>
              <p>
                Como integrante de la consultoría Heurística Educativa, colaboró
                en investigaciones nacionales: Evaluación Cualitativa del
                Programa Escuelas de Calidad y Monitoreo del Programa Dialogar y
                Descubrir del CONAFE; y estudios internacionales centrados en el
                análisis de la práctica docente en Ecuador, Nicaragua, República
                Dominicana y Paraguay.
              </p>
              <p>
                De manera reciente ha publicado diversos artículos y ponencias
                referidas a la educación para las hijas e hijos de familias
                jornaleras agrícolas migrantes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
