import React from "react";

const Conferences = ({ speakers = [] }) => {
  return (
    <section
      className='schedule section-padding'
      style={{ background: "#2d3e50" }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-12 d-flex flex-column justify-content-center align-items-center'></div>

          <div className='col-lg-12 col-12 '>
            <div className='row'>
              {speakers.map((c) => (
                <div key={c.name} className='col-lg-3 col-md-6 col-12'>
                  <div
                    className='speakers-thumb speakers-thumb-small'
                    style={{
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <img
                      style={{ width: "305px" }}
                      src={c.picture ? c.picture : "img/event/2.jpg"}
                      alt=''
                    />

                    <div className='container '>
                      <h5
                        className='speakers-title mb-0'
                        style={{
                          fontSize: "17px",
                          marginTop: "5px",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {c.name}
                      </h5>

                      <p
                        className='speakers-text mb-0'
                        style={{ textAlign: "center", color: "white" }}
                      >
                        {c.institucion}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conferences;
