import React from 'react';

const Menu = () => {
  return (
    <nav className='navbar navbar-expand-lg is-sticky '>
      <div className='container'>
        <div style={{ paddingRight: '50px' }}>
          <img
            src='images/logosLie.png'
            style={{ width: '570px', height: '70px' }}
            alt='1er Foro'
          />

          {/* <img
            src='images/img/creson.png'
            style={{ width: "70px" }}
            alt='1er Foro'
          />
          <img
            src='images/img/LOGO-UPNECH.jpg'
            style={{ width: "70px" }}
            alt='1er Foro'
          />
          <img
            src='images/img/UPN-Mexicali.jpeg'
            style={{ width: "70px" }}
            alt='1er Foro'
          />
          <img
            src='images/img/UPN-Tijuana.png'
            style={{ width: "70px" }}
            alt='1er Foro'
          />
          <img
            src='images/img/UPN-La-Paz.jpg'
            style={{ width: "70px" }}
            alt='1er Foro'
          />
          <img
            src='images/img/lie-u2152.png'
            style={{ width: "70px" }}
            alt='1er Foro'
          />*/}
        </div>
        {/* <a href='/' className='navbar-brand mx-auto mx-lg-0'>
            <i className=' brand-logo'></i>
            <img
              src='/images/logo.png'
              style={{ width: "450px" }}
              alt='1er Foro'
            />
    </a>
        <img src='/images/logo.png' style={{ width: "550px" }} alt='1er Foro' />*/}

        <div>
          <ul className='navbar-nav ms-auto'>
            <li className='nav-item'>
              <a className='nav-link click-scroll' href='/'>
                Inicio
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link click-scroll'
                href='/convocatoria/programaXII.pdf'
              >
                Programa
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link click-scroll'
                href='/convocatoria/CONVOCATORIA%20DEL%20XII%20FORO%20LIE%20PDF%20(3).pdf'
                target='_blank'
              >
                Convocatoria
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link custom-btn btn d-none d-lg-block'
                href='http://registrolie.creson.edu.mx/'
              >
                Registro
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
