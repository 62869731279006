//import logo from './logo.svg';
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Fotter from "./components/Fotter";

import { Header } from "./components/Header";

import { AppRoutes } from "./routers/AppRoutes";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <AppRoutes />

      <Fotter />
    </BrowserRouter>
  );
}

export default App;
